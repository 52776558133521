<template>

    <div id="file-uploads" class="mt-0 mb-n1" :class="{ 'pr-3' : $vuetify.breakpoint.width > 480}">
        <div v-if="view === 'tenantdocuments'">
            <div>
                <v-row class="my-3" no-gutters>
                    <v-col cols="12" class="px-0 d-flex align-center" :class="{ 'flex-wrap': $vuetify.breakpoint.xs }">
                        <div style="width:125px;">
                            <space-selection-dropdown :setSelection="false" :resetFilters="resetFiltersKey"
                                :mainViewOptions="mainViewOptions" :contactLeases="contactLeases"
                                @spaceSelectionChanged="mainViewChanged">
                            </space-selection-dropdown>

                        </div>
                        <div class="pl-2">
                            <v-menu v-model="showFilters" :close-on-content-click="false" offset-y
                                content-class="hb-elevation-large">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="d-flex align-center pl-4 pr-2 select-filter"
                                        :class="{ 'download-center-filters-active': showFilters }">
                                        <span class="font-weight-regular hb-default-font-size">{{ setFilterText.length >
                                            0 ? setFilterText : 'Filter' }}</span>
                                        <v-spacer></v-spacer>
                                        <hb-icon color="#637381">mdi-filter-variant</hb-icon>
                                    </div>
                                </template>

                                <v-card width="300">
                                    <v-card-text class="pa-4" style="overflow: auto;">
                                        <div class="hb-text-light hb-default-font-size font-weight-medium">Date Range
                                        </div>
                                        <v-select :items="dateRangeOptions" background-color="white" hide-details
                                            single-line label="Select"
                                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'hb-elevation-large' }"
                                            v-model="dateRange" id="dateRange" class="pt-0">
                                        </v-select>
                                        <span v-if="dateRange === 'Custom'">
                                            <hb-date-picker :max="todaysDate" @click:clear="customStartDate = ''"
                                                label="Start Date" :clearable="true" :solo="false"
                                                data-vv-as="start_date" v-model="customStartDate"></hb-date-picker>
                                            <hb-date-picker v-if="customStartDate.length > 0"
                                                @click:clear="customEndDate = ''" label="End Date"
                                                :min="customStartDate" :max="todaysDate" :clearable="true" :solo="false"
                                                data-vv-as="end_date" v-model="customEndDate"></hb-date-picker>
                                        </span>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions class="px-4 py-3"
                                        style="background: linear-gradient(180deg, #fafbfc 0%, #ebeff2 100%);">
                                        <hb-link color="tertiary" @click="resetFilters">Clear Filters</hb-link>
                                        <v-spacer></v-spacer>
                                        <hb-btn color="secondary" small
                                            @click="goFilter(dateRange, customStartDate, customEndDate)"
                                            :disabled="dateRange.length === 0 || (dateRange === 'Custom' && (customStartDate.length === 0 || customEndDate.length === 0))">Done</hb-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="pr-0 text-right mt-3 document-button-wrapper">
                            <hb-btn small class="mr-3 mb-1" color="secondary" @click="showGenerate = true" v-if="propertyView != 'all-property'">Generate Document</hb-btn>
                            <hb-btn small color="primary" @click="showAdd = true">Upload File</hb-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <v-card class="mb-4" elevation="0">

                <v-data-table :headers="filteredHeaders" :items="getTabData" :loading="loadingDocuments"
                    loading-text="Loading Documents..." class="hb-data-table" disable-pagination hide-default-footer
                    :custom-sort="customSort">
                    <template v-slot:item.space="{ item }">
                        <div v-if="item.id" class="d-flex align-top">
                            <hb-unit-icon small :type="item.unit_type" color="#101318" /> &nbsp;
                            {{ item.unit_number }}
                        </div>
                        <div v-else style="text-align: center">
                            <v-progress-circular indeterminate class="pa-0 ma-0 mr-2" size="16" width="2"
                                color="primary"></v-progress-circular>
                            Generating Document...
                        </div>
                    </template>
                    <template v-slot:item.filename="{ item }">
                        <span v-if="item.id">
                            {{ item.name }}
                            <div class="hb-text-light" v-if="item.src && item.src.length > 0">
                                <span style="text-transform: uppercase"
                                    v-if="item.src.split('#').shift().split('?').shift().split('/').pop().split('.')[1]">
                                    {{ getItemSrc(item) }}
                                </span>
                                <span
                                    v-if="item.src.split('#').shift().split('?').shift().split('/').pop().split('.')[1] && item.src.split('#').shift().split('?').shift().split('/').pop().split('.')[1].trim().length > 0 && item.size !== '0'">
                                    -
                                </span>
                                <span v-if="item.size !== '0'">
                                    {{ item.size | formatFileSize }}
                                </span>
                            </div>
                        </span>
                    </template>
                    <template v-slot:item.type="{ item }">
                        <span v-if="item.id">
                            {{ item.DocumentType.name }}
                        </span>
                    </template>
                    <template v-slot:item.uploaded="{ item }">
                        <div class="py-3" v-if="item.id">
                            <hb-status-general :status="item.status"></hb-status-general>
                            <div v-if="item.status === 'Signed' && item.signers[0] && item.signers[0].Contact"
                                class="hb-text-light mt-1 mb-n1">{{ item.signers[0].Contact.first }}
                                {{ item.signers[0].Contact.last }}</div>
                            <div v-if="item.status === 'Signed' && item.signers[0] && item.signers[0].signed"
                                class="hb-text-light mt-1 mb-n1">on {{ item.signers[0].signed |
                                    formatDateTimeCustom('MMM DD, YYYY [@] h:mma') }}</div>
                            <div v-else class="hb-text-light mt-1 mb-n1">{{ item.upload_date | formatDateTimeCustom('MMM
                                DD, YYYY[@] h: mma')}}</div>
                            <div v-if="item?.uploaded_by_name" class="hb-text-light mt-1 mb-n1">Uploaded by: {{
                                item.uploaded_by_name }}</div>
                            <div v-else-if="item?.generated_by_name" class="hb-text-light mt-1 mb-n1">Generated by: {{
                                item.generated_by_name }}</div>
                        </div>
                    </template>
                    <template v-slot:item.public="{ item }">
                        <span v-if="item.id">
                            {{ item.private === true ? 'No' : item.private === false ? 'Yes' : 'Not Set' }}
                        </span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <span v-if="item.id" class="d-flex justify-end">
                            <hb-menu options>
                                <v-list>
                                    <v-list-item :ripple="false" :disabled="!item.src" :href="getFileSrc(item)"
                                        target="_blank">
                                        <v-list-item-title>
                                            View/Print
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item v-if="needsSigned(item)" :ripple="false" :disabled="!item.src"
                                        @click="sign(item)">
                                        <v-list-item-title>
                                            Sign Document
                                        </v-list-item-title>
                                    </v-list-item>


                                    <v-list-item v-if="needsSigned(item)" :ripple="false" :disabled="!item.src"
                                        @click="openSendDocument(item)">
                                        <v-list-item-title>
                                            Send Sign Link
                                        </v-list-item-title>
                                    </v-list-item>


                                    <v-list-item v-if="needsSigned(item)" :ripple="false" :disabled="!item.src"
                                        @click="getSignLink(item)">
                                        <v-list-item-title>
                                            Get Sign Link
                                        </v-list-item-title>
                                    </v-list-item>

                                    <!--
                      (QAL-766) Hide the Delete button as per Karan's suggestion so that user can't delete the uploaded document
                      <v-list-item v-if="isAdmin || item.uploaded_by == $getUserData.id" @click="deleteItem(item)" :ripple="false">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    -->

                                    <v-list-item v-if="needsSigned(item)" @click="fetchData(mainView)" :ripple="false">
                                        <v-list-item-title>Refresh</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item :ripple="false" @click="openArchiveReasonModal(item)"
                                        v-if="hasPermissionToArchiveDocument">
                                        <v-list-item-title>
                                            Archive
                                        </v-list-item-title>
                                    </v-list-item>

                                    <hb-tooltip v-else>
                                        <template v-slot:item>
                                            <v-list-item disabled>
                                                Archive
                                            </v-list-item>
                                        </template>
                                        <template v-slot:body>
                                            <div style="max-width: 300px;">
                                                You do not have the permission to archive a document, please contact the
                                                administrator
                                            </div>
                                        </template>
                                    </hb-tooltip>

                                </v-list>
                            </hb-menu>

                        </span>
                        <!--
              <span v-else style="text-align: center">
                <v-progress-circular
                  indeterminate
                  class="pa-0 ma-0 mr-2"
                  size="16"
                  width="2"
                  color="primary"
                ></v-progress-circular>
                Generating Document...
              </span>
              -->
                    </template>
                </v-data-table>
            </v-card>
        </div>

        <div v-if="view === 'archivedocuments'">
            <div>
                <v-row class="my-3" no-gutters>
                    <v-col cols="12" class="px-0 d-flex align-center" :class="{ 'flex-wrap': $vuetify.breakpoint.xs }">
                        <div style="width:125px;">
                            <space-selection-dropdown :setSelection="false" :resetFilters="resetFiltersKey"
                                :mainViewOptions="mainViewOptions" :contactLeases="contactLeases"
                                @spaceSelectionChanged="mainViewChanged">
                            </space-selection-dropdown>
                        </div>
                        <div class="pl-2">
                            <v-menu v-model="showFilters" :close-on-content-click="false" offset-y
                                content-class="hb-elevation-large">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="d-flex align-center pl-4 pr-2 select-filter"
                                        :class="{ 'download-center-filters-active': showFilters }">
                                        <span class="font-weight-regular hb-default-font-size">{{ setFilterText.length >
                                            0 ? setFilterText : 'Filter' }}</span>
                                        <v-spacer></v-spacer>
                                        <hb-icon color="#637381">mdi-filter-variant</hb-icon>
                                    </div>
                                </template>

                                <v-card width="300">
                                    <v-card-text class="pa-4" style="overflow: auto;">
                                        <div class="hb-text-light hb-default-font-size font-weight-medium">Date Range
                                        </div>
                                        <v-select :items="dateRangeOptions" background-color="white" hide-details
                                            single-line label="Select"
                                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'hb-elevation-large' }"
                                            v-model="dateRange" id="dateRange" class="pt-0">
                                        </v-select>
                                        <span v-if="dateRange === 'Custom'">
                                            <hb-date-picker :max="todaysDate" @click:clear="customStartDate = ''"
                                                label="Start Date" :clearable="true" :solo="false"
                                                data-vv-as="start_date" v-model="customStartDate"></hb-date-picker>
                                            <hb-date-picker v-if="customStartDate.length > 0"
                                                @click:clear="customEndDate = ''" label="End Date"
                                                :min="customStartDate" :max="todaysDate" :clearable="true" :solo="false"
                                                data-vv-as="end_date" v-model="customEndDate"></hb-date-picker>
                                        </span>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions class="px-4 py-3"
                                        style="background: linear-gradient(180deg, #fafbfc 0%, #ebeff2 100%);">
                                        <hb-link color="tertiary" @click="resetFilters">Clear Filters</hb-link>
                                        <v-spacer></v-spacer>
                                        <hb-btn color="secondary" small
                                            @click="goFilter(dateRange, customStartDate, customEndDate)"
                                            :disabled="dateRange.length === 0 || (dateRange === 'Custom' && (customStartDate.length === 0 || customEndDate.length === 0))">Done</hb-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <v-card class="mb-4" :class="{ 'mr-6': showAside && $vuetify.breakpoint.width > 1259 }" elevation="0">
                <v-data-table :headers="archiveDocHeaders" :items="getTabData" :loading="loadingDocuments"
                    loading-text="Loading Documents..." class="hb-data-table" disable-pagination hide-default-footer
                    :custom-sort="customSort">
                    <template v-slot:item.space="{ item }">
                        <div v-if="item.id" class="d-flex align-top">
                            <hb-unit-icon small :type="item.unit_type" color="#101318" />&nbsp;
                            {{ item.unit_number }}
                        </div>
                    </template>
                    <template v-slot:item.filename="{ item }">
                        <span v-if="item.id">
                            {{ item.name }}
                            <div class="hb-text-light" v-if="item.src && item.src.length > 0">
                                <span style="text-transform: uppercase"
                                    v-if="item.src.split('#').shift().split('?').shift().split('/').pop().split('.')[1]">
                                    {{ getItemSrc(item) }}
                                </span>
                                <span
                                    v-if="item.src.split('#').shift().split('?').shift().split('/').pop().split('.')[1] && item.src.split('#').shift().split('?').shift().split('/').pop().split('.')[1].trim().length > 0 && item.size !== '0'">
                                    -
                                </span>
                                <span v-if="item.size !== '0'">
                                    {{ item.size | formatFileSize }}
                                </span>
                            </div>
                        </span>
                    </template>
                    <template v-slot:item.uploaded="{ item }">
                        <div class="py-3" v-if="item.id">
                            <hb-status-general :status="item.status"></hb-status-general>
                            <div v-if="item.status === 'Signed' && item.signers[0] && item.signers[0].Contact"
                                class="hb-text-light mt-1 mb-n1">{{ item.signers[0].Contact.first }}
                                {{ item.signers[0].Contact.last }}</div>
                            <div v-if="item.status === 'Signed' && item.signers[0] && item.signers[0].signed"
                                class="hb-text-light mt-1 mb-n1">on {{ item.signers[0].signed |
                                    formatDateTimeCustom('MMM DD, YYYY [@] h:mma') }}</div>
                            <div v-else class="hb-text-light mt-1 mb-n1">{{ item.upload_date | formatDateTimeCustom('MMM
                                DD, YYYY[@] h: mma')}}</div>
                            <div v-if="item?.uploaded_by_name" class="hb-text-light mt-1 mb-n1">Uploaded by: {{
                                item.uploaded_by_name }}</div>
                            <div v-else-if="item?.generated_by_name" class="hb-text-light mt-1 mb-n1">Generated by: {{
                                item.generated_by_name }}</div>
                        </div>
                    </template>
                    <template v-slot:item.archive="{ item }">
                        <div class="py-3" v-if="item.id">
                            <hb-status-general status="Archived"></hb-status-general>

                            <div class="hb-text-light mt-1 mb-n1">on {{ item.DocumentActions[0]?.created_at  }}</div>
                            <div class="hb-text-light mt-1 mb-n1">Archived by: {{ item.DocumentActions[0]?.Contact.first
                                + ' ' + item.DocumentActions[0]?.Contact.last }}</div>

                        </div>
                    </template>
                    <template v-slot:item.reason="{ item }">
                        {{ item.DocumentActions[0]?.reason }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <span v-if="item.id" class="d-flex justify-end">
                            <hb-menu options>
                                <v-list>

                                    <v-list-item :ripple="false" @click="unarchiveDocument(item)"
                                        v-if="hasPermissionToUnArchiveDocument">
                                        <v-list-item-title>
                                            Unarchive
                                        </v-list-item-title>
                                    </v-list-item>

                                    <hb-tooltip v-else>
                                        <template v-slot:item>
                                            <v-list-item disabled>
                                                Unarchive
                                            </v-list-item>
                                        </template>
                                        <template v-slot:body>
                                            <div style="max-width: 300px;">
                                                You do not have the permission to unarchive a document, please contact
                                                the administrator
                                            </div>
                                        </template>
                                    </hb-tooltip>

                                </v-list>
                            </hb-menu>

                        </span>
                    </template>
                </v-data-table>
            </v-card>
        </div>

        <div v-if="view === 'documentlibrary'">
            <documents-filter @applyFilters="applyFilters" :isMenuDisabled.sync="loadingDocumentTemplates"
                :isDocumentReset.sync="isDocumentReset"></documents-filter>
            <v-card class="mt-3 mb-4" :class="{ 'mr-6': showAside  && $vuetify.breakpoint.width > 1259 }" elevation="0">
                <div :style="{ maxHeight: elHeight, overflow: 'auto' }" @scroll.self="searchNextList">
                    <v-data-table :headers="libraryHeaders" :items="documents" :loading="loadingDocumentTemplates"
                        loading-text="Loading Documents..." class="hb-data-table" disable-pagination
                        hide-default-footer>
                        <template v-slot:item.name="{ item }">{{ item.name }}</template>
                        <template v-slot:item.type="{ item }">
                            <span class="text-capitalize">
                                <template v-if="item.type">{{ getDocumentTypeName(item.type) }}</template>
                                <template v-else>
                                    <span v-if="documentType.value !== 'all'">{{ documentType.name }}</span>
                                    <span v-else>-</span>
                                </template>
                            </span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <span v-if="item.id" class="d-flex justify-end">
                                <hb-menu options>
                                    <v-list>

                                        <!-- need to ability to view document that can be generated -->
                                        <!--
                    <v-list-item @click="" :ripple="false">
                      <v-list-item-title>View</v-list-item-title>
                    </v-list-item>
                    -->

                                        <v-list-item v-if="isAdmin" @click="generateDocumentFromLibrary(item)"
                                            :ripple="false">
                                            <v-list-item-title>Generate</v-list-item-title>
                                        </v-list-item>

                                    </v-list>
                                </hb-menu>

                            </span>
                            <span v-else style="text-align: center">
                                <v-progress-circular indeterminate class="pa-0 ma-0 mr-2" size="16" width="2"
                                    color="primary"></v-progress-circular>
                                Generating Document...
                            </span>
                        </template>
                    </v-data-table>
                    <v-row class="justify-center ma-0 pa-0"
                        v-if="allowScrollRequest && loadingDocumentTemplates && documents.length">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-row>
                </div>
                <v-divider></v-divider>
                <v-row class="ma-0 pa-0 px-1 document-filters-footer" v-if="getDocumentType != ''">
                    <v-col cols="8">Filters:
                        <hb-chip editable @click="isDocumentReset = true" class="ml-2">
                            {{ getDocumentType.name === 'Autopay' ? 'AutoPay' : getDocumentType.name }}
                        </hb-chip>
                    </v-col>
                    <v-col cols="4">
                        <div class="float-right" style="position:relative;top:2px;">
                            <hb-btn small color="secondary" @click="isDocumentReset = true">Reset Filters</hb-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </div>


        <hb-modal v-model="showGenerate" show-help-link v-if="showGenerate" size="medium" title="Choose Document"
            @close="closeGenerate">
            <template v-slot:subheader>
                Please select a document to generate from the list below.
            </template>
            <template v-slot:content>
                <div class="pb-5 px-6">
                    <v-select :items="getAllDocumentTypes" id="document_type" name="document_type" flat item-text="name"
                        item-value="value" label="Select Document Type" v-model="document_generate_type"
                        @change="resetGenerateDocuments" v-validate="'required'" single-line hide-details
                        data-vv-as="document_type">
                    </v-select>
                    <v-select :items="documents" id="document_id" name="document_id" flat item-text="name"
                        item-value="id" label="Select Document" :no-data-text="documentText" v-model="document"
                        v-validate="'required'" return-object single-line hide-details data-vv-as="document_id">
                        <template v-slot:append-item>
                            <div v-intersect="getDocumentTemplates" class="d-flex justify-center">
                                <v-progress-circular indeterminate color="primary"
                                    v-if="loadingDocumentTemplates"></v-progress-circular>
                            </div>
                        </template>
                    </v-select>
                    <v-select v-if="(mainView.id === null || view === 'documentlibrary') && contactLeases.length > 1"
                        :items="contactLeases" id="space_id" name="space_id" flat item-text="Unit.number"
                        item-value="id" return-object label="Select Space" v-model="documentSpace"
                        v-validate="'required'" single-line hide-details data-vv-as="document_space">
                        <template v-slot:append-item>
                            <div v-intersect="getDocumentTemplates" class="d-flex justify-center">
                                <v-progress-circular indeterminate color="primary"
                                    v-if="loadingDocumentTemplates"></v-progress-circular>
                            </div>
                        </template>
                        <template v-slot:selection="data">
                            <span :class="leaseSelectionClass(data.item)">{{ data.item.Unit.number }}</span>
                        </template>
                        <template v-slot:item="data">
                            <span :class="leaseSelectionClass(data.item)">{{ data.item.Unit.number }}</span>
                        </template>
                    </v-select>
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary"
                   
                    @click="createDoc(documentSpace.id ? documentSpace : mainView.id === null && contactLeases.length === 1 ? contactLeases[0] : mainView.id && view === 'tenantdocuments' ? mainView : '')">Save</hb-btn>
                <span v-show="isLoading('generate')">
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </template>
        </hb-modal>

        <upload-file v-model="showAdd" v-if="showAdd" type="file" model="national-accounts" :properties="propertyView"
            :isNational="contact.NationalAccount?.id ? true : false" :foreign_id="contact.id" slot="body"
            :multiple-leases="mainView.id === null && contactLeases.length > 0 ? contactLeases : []"
            @close="showAdd = false" @refetch="resetFilters()">
        </upload-file>

        <hb-modal v-model="showEdit" show-help-link v-if="showEdit" size="medium" title="Edit Visibility">
            <template v-slot:content>
                <div class="px-6 pb-5 d-flex align-top">
                    <v-checkbox v-model="makePublic" hide-details>
                    </v-checkbox>
                    <div class="hb-text-night mt-5">Make document visible<br />
                        <span class="hb-text-light text-body-2">Making this document visible will give the tenant access
                            to it in their account on the website.</span>
                    </div>
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="editConfirm(selected)">Save</hb-btn>
            </template>
        </hb-modal>

        <hb-modal v-model="showDelete" show-help-link v-if="showDelete" size="medium" title="Delete Upload"
            confirmation>
            <template v-slot:content>
                <div class="py-4 px-6">
                    Are you sure you want to delete the selected upload?
                    <br /><br />
                    This action cannot be undone.
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="destructive" :disabled="isLoading('generate')" @click="deleteConfirm">Delete</hb-btn>
                <span v-show="isLoading('generate')">
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </template>
        </hb-modal>

        <sign @close="sign_process = false" @completed="closeSignProcess" :property_id="propertyView"
            v-if="uploads_to_sign.length && sign_process" :uploads="uploads_to_sign" :signer_id="signer_id"></sign>

        <hb-modal v-model="show_link" show-help-link v-if="show_link" size="medium" title="Signing Link"
            close-instead-of-cancel @close="link = null">
            <template v-slot:subheader>
                You can text or email this link to be able to sign the document:
            </template>
            <template v-slot:content>
                <div class="pa-6">
                    {{ link.shortUrl }}
                </div>
            </template>
        </hb-modal>

        <hb-modal v-model="sendDocument" v-if="sendDocument" :footerOff="sendDocStep === 1"
            :size="sendDocStep === 2 ? 'large' : 'medium'" show-help-link
            :title="sendDocStep === 2 && send_doc_data.method === 'email' ? 'Email Document for Signing' : sendDocStep === 2 && send_doc_data.method !== 'email' ? 'Text Document for Signing' : 'Ready to Sign Document'">
            <template v-slot:subheader>
                {{ sendDocStep === 1 ? 'How would you like the tenant to sign the document?' : 'Enter a message and send
                this document for signing.'}}
            </template>
            <template v-slot:content>
                <div class="pa-0">
                    <v-card elevation="0" class="ti-widget">
                        <div class="v-card__content ti-widget__content py-3 px-6" v-if="sendDocStep === 1">
                            <v-row class="ma-0 pa-0 d-flex align-center mt-3"
                                v-if="send_doc_data.contact.Phones.length > 0">
                                <v-col cols="8" class="ma-0 pa-0 pr-1">
                                    <v-select :items="send_doc_data.contact.Phones" item-text="phone" item-value="id"
                                        return-object background-color="white" dense hide-details label="Phone Number"
                                        v-model="sendDocPhone" :readonly="send_doc_data.contact.Phones.length === 1"
                                        :append-icon="send_doc_data.contact.Phones.length === 1 ? '' : 'mdi-menu-down'"
                                        id="sendDocPhone">
                                        <template v-slot:selection="data">
                                            {{ data.item.phone | formatPhone }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.phone | formatPhone }}
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="4" class="ma-0 pa-0 align-right">
                                    <hb-btn color="primary" large @click="sendDocNextStep('text', sendDocPhone)">Send
                                        Text</hb-btn>
                                </v-col>
                            </v-row>

                            <v-row class="ma-0 pa-0 d-flex align-center mt-3">
                                <v-col cols="8" class="ma-0 pa-0 pr-1">
                                    <v-text-field v-if="send_doc_data.contact.email" hide-details v-model="sendDocEmail"
                                        label="Email" id="sendDocEmail" dense readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4" class="ma-0 pa-0 align-right">
                                    <hb-btn color="primary" large @click="sendDocNextStep('email', sendDocEmail)">Send
                                        Email</hb-btn>
                                </v-col>
                            </v-row>

                            <v-row class="ma-0 pa-0 d-flex align-center py-5">
                                <v-col cols="5" class="ma-0 pa-0">
                                    <v-divider style="border-color:#f0f0f0;"></v-divider>
                                </v-col>
                                <v-col cols="2" class="ma-0 pa-0 d-flex justify-center">
                                    or
                                </v-col>
                                <v-col cols="5" class="ma-0 pa-0">
                                    <v-divider style="border-color:#f0f0f0;"></v-divider>
                                </v-col>
                            </v-row>

                            <v-row class="ma-0 pa-0 d-flex align-center pb-3">
                                <v-hover v-slot:default="{ hover }">
                                    <v-btn width="100%" depressed :ripple="false" large class="hb-full-width-button"
                                        @click="sign(itemToSign)">
                                        <span class="hb-larger-font-size hb-text-night font-weight-regular">
                                            Sign on this Device
                                        </span>
                                    </v-btn>
                                </v-hover>
                            </v-row>
                        </div>
                        <div class="v-card__content ti-widget__content" v-if="sendDocStep === 2">
                            <v-row class="ma-0 pa-0">
                                <v-col cols="3" class="row-title pl-6 lease-column-1 font-weight-medium">
                                    Document Name
                                </v-col>
                                <v-col cols="9" class="px-5">
                                    {{ send_doc_data.docs[0].name }}
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="ma-0 pa-0">
                                <v-col cols="3" class="row-title pl-6 lease-column-1 font-weight-medium">
                                    Message
                                </v-col>
                                <v-col cols="9" class="px-5 pt-2 pb-4">
                                    <v-textarea dense flat hide-details rows="1" v-model="send_doc_data.message">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="ma-0 pa-0">
                                <v-col cols="3" class="row-title pl-6 lease-column-1 font-weight-medium">
                                    Method
                                </v-col>
                                <v-col cols="9" class="px-5 pt-5 pb-4">
                                    <v-text-field hide-details v-model="methodDisplay.text" :label="methodDisplay.label"
                                        id="methodDisplay" dense readonly>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                        </div>
                    </v-card>
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" :disabled="isLoading($options.name)" @click="sendSignLink">Send</hb-btn>
                <span v-show="isLoading($options.name)">
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </template>
        </hb-modal>

        <hb-modal v-model="showModaltoArchive" v-if="showModaltoArchive" size="medium" title="Reason to Archive">
            <template v-slot:content>
                <v-card-text class="pa-0 ma-0">
                    <v-container class="pa-0 ma-0">
                        <hb-form label="Reason" full>
                            <HbTextarea v-model="archive.reason" placeholder="Enter Reason"
                                v-validate.disable="'required|max:999'" data-vv-name="reason" data-vv-as="Reason"
                                name="reason" id="reason" :rows="3" :error="errors.collect('reason').length > 0" />
                        </hb-form>
                    </v-container>
                </v-card-text>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="archiveDocument(selected)">Archive</hb-btn>
            </template>
        </hb-modal>
    </div>

</template>
<script type="text/babel">

import api from '../../../assets/api.js';
import Status from '../../includes/Messages.vue';
import Loader from '../../assets/CircleSpinner.vue';
import moment from 'moment';
import HbDatePicker from '../../assets/HummingbirdDatepicker';
import Modal from '../../assets/Modal.vue';
import Dropdown from '../../assets/Dropdown.vue';
import DropdownMenu from '../../assets/DropdownMenu.vue';
import SendDocument from '../../includes/SendDocument.vue';
import UploadFile from '../../assets/UploadFile.vue';
import GenerateLease from '../../documents/GenerateLease.vue';
import Sign from '../../includes/SignDocuments.vue';
import { EventBus } from '../../../EventBus.js';
import { mapGetters, mapMutations, mapActions } from 'vuex';
// import PublicDocuments from '../leases/PublicDocuments.vue';
import DocumentsFilter from '../DocumentsFilter.vue';
import { notificationMixin } from '@/mixins/notificationMixin.js';
import SpaceSelectionDropdown from '../../settings/spaceSelectionDropdown/spaceSelectionDropdown.vue';
import HbUnitIcon from '../../../aviary/HbUnitIcon.vue'

export default {
    name: 'NationalDocuments',
    data() {
        return {
            show_link: null,
            link: null,
            sign_process: false,
            signer: {},
            showEdit: false,
            showAdd: false,
            showDelete: false,
            showGenerate: false,
            documents: [],
            document: {},
            documentSpace: {},
            selected: {},
            hasSubmitted: false,
            uploads: [],
            signer_id: null,
            uploads_to_sign: [],
            send_document_id: null,
            sendDocument: false,
            completed: false,
            lease: {},
            headers: [
                { text: 'Space', value: 'space', width: '100px' },
                { text: 'File Name', value: 'filename', width: '300px' },
                { text: 'Document Type', value: 'type', width: '150px' },
                { text: 'Status', value: 'uploaded', width: '150px' },
                { text: 'Visible', value: 'public', width: '100px' },
                { text: '', value: 'actions', align: 'right', sortable: false },
            ],
            libraryHeaders: [
                { text: 'Document Name', value: 'name', width: 640 },
                { text: 'Document Type', value: 'type' },
                { text: '', value: 'actions', align: 'right', sortable: false },
            ],
            statuses: [
                'error',
                'not_processed',
                'loading',
                'ready',
                'loaded',
                'initiated',
                'complete',
                'finished',
            ],
            send_doc_data: {
                message: '',
                method: '',
                contact: {},
                docs: []
            },
            status_message: '',
            mainView: {
                id: null,
                Unit: {
                    number: 'Tenant',
                    type: 'tenant',
                }
            },
            sendDocStep: 1,
            sendDocPhone: '',
            sendDocEmail: '',
            methodDisplay: {
                text: '',
                label: '',
            },
            itemToSign: {},
            showFilters: false,
            dateRangeOptions: ['All Time', 'Last 30 Days', 'Custom'],
            dateRange: '',
            customStartDate: '',
            customEndDate: '',
            setFilterText: '',
            todaysDate: '',
            loadingDocuments: false,
            allowScrollRequest: true,
            loadingDocumentTemplates: false,
            documentMethod: 'un-signed',
            documentType: { value: 'all' },
            isDocumentReset: false,
            document_generate_type: '',
            isDocumentTypeChanged: false,
            fetchUnSignedDocumentUsingScroll: false,
            documentText: 'Loading documents...',
            showModaltoArchive: false,
            is_archived: 0,
            archive: {
                reason: ''
            },
            archiveDocHeaders: [
                { text: 'File Name', value: 'filename', width: '150px' },
                { text: 'Orignal Status', value: 'uploaded', width: '150px' },
                { text: 'Status', value: 'archive', width: '100px' },
                { text: 'Reason', value: 'reason', width: '250px' },
                { text: '', value: 'actions', align: 'right', sortable: false },
            ],
            resetFiltersKey: 0,
        }
    },
    mixins: [notificationMixin],
    async created() {
        let data = this.propertyView;
        this.fetchData();
        await this.fetchDocumentTypes();
        await this.fetchUnSignedDocumentTypes();
        EventBus.$on('pandadoc_generation_update', this.handleDocumentUpdate);
        EventBus.$on('document_signed', this.fetchData);
        this.todaysDate = moment().startOf('day').format('YYYY-MM-DD');
    },
    beforeDestroy() {
        EventBus.$off('pandadoc_generation_update', this.handleDocumentUpdate);
        EventBus.$off('document_signed', this.fetchData);
    },
    components: {
        Modal,
        UploadFile,
        GenerateLease,
        Dropdown,
        Loader,
        DropdownMenu,
        SendDocument,
        HbDatePicker,
        Sign,
        Status,
        DocumentsFilter,
        SpaceSelectionDropdown,
        HbUnitIcon
    },
    filters: {
        getIcon(icon) {
            if (type === 'tenant') {
                return 'mdi-account';
            } else if (type === 'storage') {
                return 'mdi-pencil';
            }
        },
    },
    computed: {
        ...mapGetters({
            isAdmin: 'authenticationStore/isAdmin',
            getUserData: 'authenticationStore/getUserData',
            getDocuments: 'documentsStore/getDocuments',
            getDocumentTypes: 'documentsStore/getDocumentTypes',
            getUnSignedDocumentTypes: 'documentsStore/getUnSignedDocumentTypes',
        }),
        filteredHeaders() {
            if (this.contact.national_account_id && this.propertyView == 'all-property') {
                return this.headers.filter(header => header.value !== 'space');
            }
            return this.headers;
        },
        getAllDocumentTypes() {
            if (this.getDocumentTypes.length && this.getUnSignedDocumentTypes.length) {
                let documentTypes = [];
                this.getDocumentTypes.map((type, index) => {
                    if (type.value !== 'all')
                        documentTypes.push(type);
                });
                this.getUnSignedDocumentTypes.map((type, index) => {
                    if (type.value !== 'all')
                        documentTypes.push(type);
                });
                return documentTypes;
            }
        },
        getDocumentType() {
            return (this.documentType.value != 'all') ? this.documentType : '';
        },
        elHeight() {
            let pHeight = document.documentElement.clientHeight;

            let elementHeight = 55;
            if (pHeight < 768) {
                elementHeight = 50;
            } else if (pHeight >= 1050) {
                elementHeight = 65;
            } else if (pHeight >= 860) {
                elementHeight = 60;
            }

            return `${elementHeight}vh`;
        },
        mainViewOptions() {
            var options = [];
            options.push({
                id: null,
                Unit: {
                    number: 'Tenant',
                    type: 'tenant',
                }
            });
            if (this.propertyView != 'all-property') {
                this.contactLeases.forEach(lease => {
                    options.push({
                        id: lease.id,
                        end_date: lease.end_date,
                        Unit: {
                            number: lease.Unit.number,
                            type: lease.Unit.type,
                        }
                    });
                })
            }
            return options;
        },
        hasPermissionToArchiveDocument() {
            return this.hasPermission('archive_document');
        },
        hasPermissionToUnArchiveDocument() {
            return this.hasPermission('unarchive_document');
        },
        getTabData() {
            let view = this.view;
            let clonedUploads = this.uploads;
            if (view == 'tenantdocuments') return clonedUploads.filter(item => item.is_archive == '0')
            else if (view == 'archivedocuments') return clonedUploads.filter(item => item.is_archive == '1')
        }
    },
    methods: {
        ...mapActions({
            fetchDocumentsData: 'documentsStore/fetchDocuments',
            fetchDocumentTypes: 'documentsStore/fetchDocumentTypes',
            fetchUnSignedDocumentTypes: 'documentsStore/fetchUnSignedDocumentTypes'
        }),
        ...mapMutations({
            setDocuments: 'documentsStore/setDocuments',
        }),
        async resetGenerateDocuments() {
            await this.setDocuments([]);
            this.documentText = 'Loading documents...'
            this.documents = [];
            this.fetchDocuments(true);
        },
        getDocumentTypeName(type) {
            if (this.documentMethod == 'signed') {
                let signedType = this.getDocumentTypes.find(item => item.value == type);
                return (this.getDocumentTypes.length && signedType) ? signedType.name : type;
            } else {
                let unSignedType = this.getUnSignedDocumentTypes.find(item => item.value == type);
                return (this.getUnSignedDocumentTypes.length && unSignedType) ? unSignedType.name : type;
            }
        },
        customSort(items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] === 'space') {
                    var fa = '';
                    if (a.id && a.unit_number) {
                        fa = a.unit_number.toLowerCase();
                    } else {
                        ''
                    }

                    var fb = '';
                    if (b.id && b.unit_number) {
                        fb = b.unit_number.toLowerCase();
                    } else {
                        ''
                    }
                    if (!isDesc[0]) {

                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (index[0] === 'filename') {
                    var fa = '';
                    if (a.id && a.name) {
                        fa = a.name.toLowerCase();
                    } else {
                        ''
                    }

                    var fb = '';
                    if (b.id && b.name) {
                        fb = b.name.toLowerCase();
                    } else {
                        ''
                    }
                    if (!isDesc[0]) {

                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (index[0] === 'type') {
                    var fa = '';
                    if (a.id && a.DocumentType && a.DocumentType.name) {
                        fa = a.DocumentType.name.toLowerCase();
                    } else {
                        ''
                    }

                    var fb = '';
                    if (b.id && b.DocumentType && b.DocumentType.name) {
                        fb = b.DocumentType.name.toLowerCase();
                    } else {
                        ''
                    }
                    if (!isDesc[0]) {

                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (index[0] === 'uploaded') {
                    var fa = '';
                    if (a.id && a.status) {
                        fa = a.status.toLowerCase();
                    } else {
                        ''
                    }

                    var fb = '';
                    if (b.id && b.status) {
                        fb = b.status.toLowerCase();
                    } else {
                        ''
                    }
                    if (!isDesc[0]) {

                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (index[0] === 'public') {
                    var fa = '';
                    if (a.id && a.private && a.private === true) {
                        fa = 'no';
                    } else if (a.id && a.private && a.private === false) {
                        fa = 'yes';
                    } else {
                        ''
                    }

                    var fb = '';
                    if (b.id && a.private && b.private === true) {
                        fb = 'no';
                    } else if (b.id && a.private && b.private === false) {
                        fb = 'yes';
                    } else {
                        ''
                    }
                    if (!isDesc[0]) {

                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (index[0] === 'reason') {
                    var fa = a.DocumentActions[0].reason.toLowerCase();
                    var fb = b.DocumentActions[0].reason.toLowerCase();

                    if (!isDesc[0]) {

                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
            });

            return items;
        },

        openSendDocument(item) {
            this.sendDocStep = 1;
            this.send_doc_data.docs = [{ upload_id: item.id, name: item.name }];
            this.send_doc_data.contact = item.signers.find(i => !i.signed).Contact;

            if (this.send_doc_data.contact.email.length) {
                this.sendDocEmail = this.send_doc_data.contact.email;
            }

            if (this.send_doc_data.contact.Phones && this.send_doc_data.contact.Phones.length) {
                this.sendDocPhone = this.send_doc_data.contact.Phones[0];
            }
            this.itemToSign = item;
            this.sendDocument = true;

        },

        sendDocNextStep(type, selection) {
            this.sendDocStep = 2;
            this.send_doc_data.message = "Please click the link to sign your documents";
            if (type === 'text') {
                this.send_doc_data.method = selection.id;
                this.methodDisplay = {
                    text: this.$options.filters.formatPhone(selection.phone),
                    label: 'Phone',
                }
            } else {
                this.send_doc_data.method = 'email';
                this.methodDisplay = {
                    text: selection,
                    label: 'Email',
                }
            }
        },

        async getSignLink(item, signer) {

            var data = {
                docs: [{ upload_id: item.id }],
                contact_id: item.signers.find(i => !i.signed).Contact.id,
                property_id: this.propertyView,
            };

            let response = await api.post(this, api.GET_SIGN_LINK, data);
            // let session_id = await this.getSession(file, signer);
            this.link = response.link;
            this.show_link = true;
            const linkk = JSON.parse(this.link);
            console.log(`Linkk ${linkk}`);

        },


        async sendSignLink() {

            var data = {
                docs: this.send_doc_data.docs,
                contact_id: this.$route.params.contact_id,
                property_id: this.propertyView,
                method: this.send_doc_data.method,
                message: this.send_doc_data.message
            };

            // console.log(data);

            try {
                this.startLoading(this.$options.name);
                await api.post(this, api.SEND_SIGN_LINK, data, "sendSignLink");
                if (this.send_doc_data.method === 'email') {
                    this.showMessageNotification({ type: 'success', description: "An email with instructions has been sent to this customer." });
                } else {
                    this.showMessageNotification({ type: 'success', description: "A text with instructions has been sent to this customer." });
                }
                this.stopLoading(this.$options.name);
                this.closeSendDocument();
            } catch (error) {
                this.showMessageNotification({ type: 'error', description: error });
            }

        },


        async handleDocumentUpdate(data) {

            let status = '';
            console.log("data", data);
            switch (data.status) {
                case 'error':
                    this.showMessageNotification({ type: 'error', description: "An error occurred, please try again" });
                    break;
                case 'not_processed':
                    status = "loading";
                case 'loading':
                    break;
                case 'ready':
                    status = "Ready to Sign";
                    break;
                case 'loaded':

                    await this.fetchData(this.mainView);
                    if (data.Upload.signers.length) {
                        status = "Ready to Sign";
                    } else {
                        status = "Generated";
                    }
                    break;
                case 'initiated':
                    status = "Signing Started";
                    break;
                case 'complete':
                    status = "Signing complete. Finalizing document";

                    break;
                case 'finished':
                    this.showMessageNotification({ type: 'success', description: "Document is being generated." });
                    await this.fetchData(this.mainView);
                    return;
                default:
                    break;
            }
            let index = this.uploads.findIndex(u => u.id === data.Upload.id);
            if (index >= 0) {
                this.$set(this.uploads[index], 'status', status);
            }
        },
        needsSigned(item) {
            return item.signers.length && item.signers.filter(i => !i.signed).length;
        },
        status_rank(item) {
            return this.statuses.indexOf(item.status);
        },


        async download(file) {
            let response = await this.$http.get(
                api.LEASES + file.lease_id + '/uploads/' + file.id + '/download',
                null,
                { responseType: 'arraybuffer' }
            );

            var blob = new Blob([response.data.data], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "export.pdf";
            link.click();
        },

        async sign(item) {

            // console.log("item", item);
            this.uploads_to_sign = [item];
            this.sign_process = true;
        },

        async updateUpload(data) {

            let response = await api.put(this, api.LEASES + data.lease_id + '/uploads/' + data.uuid + '/signed', { signer_id: this.signer.id });
            return response.session_id;
        },

        async getSession(file, signer) {
            let response = await api.post(this, api.LEASES + file.lease_id + '/uploads/' + file.id + '/session', { signer_id: signer.id });
            return response.session_id;
        },
        searchNextList({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if ((Math.ceil(scrollTop) + clientHeight >= scrollHeight) && !this.loadingDocumentTemplates) {
                if (this.allowScrollRequest) {
                    this.loadingDocumentTemplates = true;
                    this.fetchUnSignedDocumentUsingScroll = true
                    this.fetchDocuments();
                }
            }
        },
        applyFilters(payload) {
            if (this.documentMethod != payload.method || this.documentType.value != payload.type.value) {
                this.setDocuments([]);
                this.documentText = 'Loading documents...'
                this.documents = [];
            }
            this.loadingDocumentTemplates = true;
            this.isDocumentReset = false;
            this.documentMethod = payload.method;
            this.documentType = payload.type;
            this.fetchDocuments();
        },
        async fetchDocuments(documentGenerateType = false) {
            this.loadingDocumentTemplates = true;
            if (this.documentMethod == 'un-signed' && !documentGenerateType) {
                this.fetchNonSignedDocuments();
                return;
            }
            let limit = 30;
            let offset = this.documents.length == 0 ? 1 : Math.ceil(this.documents.length / limit) + 1;
            let params = '';
            if (documentGenerateType) {
                params = `?limit=${limit}&offset=${offset}&type[]=${this.document_generate_type}&is_national_account=true`;
            } else if (this.getDocumentType != '') {
                params = `?limit=${limit}&offset=${offset}&type[]=${this.getDocumentType.value}&is_national_account=true`;
            } else {
                params = `?limit=${limit}&offset=${offset}&fetch_details=true&is_national_account=true`;
            }

            console.log("Before Request: ", moment().format('MMMM Do YYYY, h:mm:ss a'));
            let path = '';

            if (this.propertyView && documentGenerateType) {
                // Check if propertyView is 'all-property'
                if (this.propertyView === 'all-property') {
                    path = '&property_ids=';  // Send empty array for property_ids
                } else {
                    path = `&property_ids=${this.propertyView}`;  // Send the actual property ID
                }
            }
            api.get(this, api.DOCUMENT.slice(0, -1) + params + path).then(r => {

                if (r.documents.length == 0) {
                    this.allowScrollRequest = false;
                    this.documentText = 'No data found.'
                } else {
                    this.allowScrollRequest = true;
                }
                this.tempDocs = [...this.documents, ...r.documents];
                this.documents = this.tempDocs;
                this.loadingDocumentTemplates = false;

                console.log("After Request: ", moment().format('MMMM Do YYYY, h:mm:ss a'));
            }).catch(err => {
                // this.showMessageNotification({description: err});
                this.loadingDocumentTemplates = false;
            });
        },

        async fetchNonSignedDocuments() {
            if (!this.fetchUnSignedDocumentUsingScroll) {
                await this.setDocuments([]);
                this.documentText = 'Loading documents...'
                this.fetchUnSignedDocumentUsingScroll = false
            }
            let limit = 30;
            let offset = this.getDocuments.length == 0 ? 1 : Math.ceil(this.getDocuments.length / limit) + 1;
            let documentTypes = '';
            if (this.getDocumentType != '') {
                documentTypes = `document_type=un-signed&type[]=${this.getDocumentType.value}`;
            } else {
                documentTypes = `document_type=un-signed`;
            }
            let path = (this.propertyView && this.propertyView !== 'all-property') ? `&property_ids[]=${this.propertyView}` : '';
            let data = {
                component: this,
                params: api.DOCUMENT.slice(0, -1) + `?limit=${limit}&offset=${offset}&${documentTypes}` + path
            }
            try {
                const currentDocuments = await this.fetchDocumentsData(data);
                if (currentDocuments.length === 0) {
                    this.allowScrollRequest = false;
                    this.documentText = 'No data found.'
                }
                this.documents = this.getDocuments;
                this.loadingDocumentTemplates = false;
            } catch (err) {
                this.showMessageNotification({ description: err });
                this.loadingDocumentTemplates = false;
            } finally {
                this.loadingDocumentTemplates = false;
            }
        },

        async getDocumentTemplates(entries, observer, isIntersecting) {
            if (isIntersecting && this.allowScrollRequest && !this.loadingDocumentTemplates) {
                this.loadingDocumentTemplates = true;
                this.fetchDocuments(true);
            }
        },

        async fetchData(lease, dateRange, customStartDate, customEndDate) {
            this.uploads = [];
            this.loadingDocuments = true;

            let payload = {};
            if (this.propertyView && (!lease || (lease.id === null || !lease.id))) payload = this.propertyView !== 'all-property' ? { property_id: this.propertyView } : payload;
            else if (this.propertyView) payload = this.propertyView !== 'all-property' ? { property_id: this.propertyView, lease_id: lease.id } : { lease_id: lease.id }

            let r = await api.get(this, api.NATIONAL_ACCOUNT + this.$route.params.contact_id + '/uploads', payload);
            var mapUploads = r.uploads.map(u => {
                if (u?.signers[0]?.signed) {
                    u.status = 'Signed';
                } else if (u?.signers[0] && !u?.signers[0]?.signed) {
                    u.status = 'Ready to sign';
                } else if (u.uploaded_by) {
                    u.status = 'Uploaded';
                } else if (!u.signers || !u.signers.length) {
                    u.status = 'Generated';
                }

                return u;
            });

            if (dateRange === 'Last 30 Days') {
                this.uploads = mapUploads.filter(upload => {
                    if (upload.status === 'Signed' && upload.signers[0] && upload.signers[0].signed) {
                        return moment(upload.signers[0].signed).subtract(8, 'hours').startOf('day') >= moment().startOf('day').subtract(30, 'days');
                    } else {
                        return moment(upload.upload_date).subtract(8, 'hours').startOf('day') >= moment().startOf('day').subtract(30, 'days');
                    }
                });

            } else if (dateRange === 'Custom') {
                this.uploads = mapUploads.filter(upload => {
                    if (upload.status === 'Signed' && upload.signers[0] && upload.signers[0].signed) {
                        return (moment(upload.signers[0].signed).subtract(8, 'hours').startOf('day') >= moment(customStartDate).startOf('day')) && (moment(upload.signers[0].signed).subtract(8, 'hours').startOf('day') <= moment(customEndDate).startOf('day'));
                    } else {
                        return (moment(upload.upload_date).subtract(8, 'hours').startOf('day') >= moment(customStartDate).startOf('day')) && (moment(upload.upload_date).subtract(8, 'hours').startOf('day') <= moment(customEndDate).startOf('day'));
                    }
                });
            } else {
                this.uploads = mapUploads;
            }

            this.loadingDocuments = false;
        },
        deleteItem(doc) {
            this.showDelete = true;
            this.selected = doc;
        },
        deleteConfirm() {
            api.delete(this, api.UPLOADS, this.selected.id).then(r => {
                this.showDelete = false;
                this.fetchData(this.mainView);
            });
        },
        getFileSrc(file) {
            let src = file.Revisions.length ? file.Revisions[file.Revisions.length - 1].src : file.src;
            if (!src) return;
            let domain = /:\/\/([^\/]+)/.exec(src)[1];
            domain = domain.split('.')[1] ? domain.split('.')[0] : false;
            if (domain && domain.includes('api') && domain !== process.env.VUE_APP_API_SUBDOMAIN) {
                src = src.replace(domain, process.env.VUE_APP_API_SUBDOMAIN);
            }
            return src;
        },
        async createDoc(lease) {
            if (!this.document.id) {
                this.showMessageNotification({ type: 'error', description: "Please select a document." });
                return;
            }
            if (!lease.id) {
                this.showMessageNotification({ type: 'error', description: "Please select a space." });
                return;
            }

            let body = {
                lease_id: lease.id,
                document: this.document
            };
            let r = await api.get(this, api.LEASES + lease.id + '/documents/' + this.document.id + '/generate?type=' + this.document_generate_type, null, 'generate');

            setTimeout(() => {
                this.uploads.push({
                    DocumentType: {},
                    id: null,
                    mimetype: null,
                    name: null,
                    signers: [],
                    size: null,
                    src: null,
                    type: null,
                    upload_date: null,
                    uploaded_by: null,
                    private: null,
                    lease_id: null,
                    unit_number: null,
                    unit_type: null,
                })
            }, 1000);
            this.showGenerate = false;

            // to show the user that, Action is underway. So, full document reloads are no longer necessary.
            this.showMessageNotification({ type: 'success', description: "Document is being generated." });
            // await this.fetchData();

            this.closeGenerate();
            this.hasSubmitted = false;
            this.resetFiltersKey++;
        },
        async closeSignProcess() {
            this.sign_process = false;
            this.signer_id = null;
            await this.fetchData(this.mainView);
            this.uploads_to_sign = [];
            this.active_item = {};
        },

        closeSendDocument() {
            this.send_document_id = null;
            this.sendDocument = false;
        },

        generateDocumentFromLibrary(item) {
            if (item.type) {
                this.document_generate_type = item.type.toLowerCase();
            } else if (this.documentType.value !== 'all') {
                this.document_generate_type = this.documentType.value
            }
            this.document = item;
            this.showGenerate = true;
        },

        openEdit(item) {
            this.showEdit = true;
            this.selected = item;
            this.makePublic = !item.private;
        },

        editConfirm(selected) {

            api.put(this, api.UPLOADS + selected.id, { private: !this.makePublic }).then(r => {
                this.fetchData(this.mainView);
                this.selected = {};
                this.makePublic = '';
                this.showEdit = false;
            });
        },

        async closeGenerate() {
            this.showGenerate = false;
            this.selected = {};
            this.document = {};
            this.documentSpace = {};
            this.document_generate_type = '';
            await this.setDocuments([]);
            // this.documentText = 'Loading documents...'
            // this.documents = [];
            // await this.fetchDocuments();
        },

        async resetFilters() {
            this.setFilterText = '';
            this.dateRange = '';
            this.customStartDate = '';
            this.customEndDate = '';
            this.showFilters = false;
            await this.setDocuments([]);
            this.documentText = 'Loading documents...'
            this.documents = [];
            this.resetFiltersKey++;

            await this.fetchData(this.mainView);
        },

        async goFilter(dateRange, customStartDate, customEndDate) {
            this.setFilterText = dateRange;
            if (dateRange !== 'Custom') {
                this.customStartDate = '';
                this.customEndDate = '';
            }
            this.showFilters = false,
                await this.fetchData(this.mainView, dateRange, customStartDate, customEndDate);
        },
        async mainViewChanged(filters) {
            this.uploads = [];
            await this.fetchData(filters);
        },
        isLeaseEnded(lease) {
            if (lease.end_date?.length) {
                return true;
            }

            return false;
        },
        leaseSelectionColor(lease) {
            return this.isLeaseEnded(lease) ? '#637381' : '#101318';
        },
        leaseSelectionClass(lease) {
            return this.isLeaseEnded(lease) ? 'hb-text-night-light' : 'hb-text-night';
        },
        openArchiveReasonModal(item) {
            this.showModaltoArchive = true;
            this.selected = item;
        },
        archiveDocument(selected) {
            console.log(this.archive.reason);
            if (this.archive.reason == '') {
                this.showMessageNotification({ type: 'error', description: 'Please enter reason to archive document.' });
                return;
            }

            const payload = {
                private: true,
                is_archive: true,
                reason: this.archive.reason,
                action: 'archive'
            }
            api.put(this, api.UPLOADS + selected.id, payload).then(r => {
                this.fetchData(this.mainView);
                this.selected = {};
                this.showModaltoArchive = false;
                this.archive.reason = '';
            }).catch(err => {
                this.showMessageNotification({ type: 'error', description: err });
                this.showModaltoArchive = false;
            });;
        },
        unarchiveDocument(item) {
            const payload = {
                private: true,
                is_archive: false,
                action: 'unarchive'
            }
            api.put(this, api.UPLOADS + item.id, payload).then(r => {
                this.fetchData(this.mainView);
                this.selected = {};
            }).catch(err => {
                this.showMessageNotification({ type: 'error', description: err });
            });;
        },
        getItemSrc(item) {
            return item?.src.split('#').shift().split('?').shift().split('/').pop().split('.')[1].trim();
        }
    },
    props: ['contact', 'showAside', 'contactLeases', 'view', 'propertyView'],
    watch: {
        document() {
            if (this.document.id) {
                this.hasSubmitted = false;
            }
        },
        async view() {
            if (this.view === 'tenantdocuments' || this.view === 'archivedocuments') {
                await this.resetFilters();
            } else {
                this.isDocumentReset = true;
            }
        },
        customStartDate() {
            if (this.customStartDate === '') {
                this.customEndDate = '';
            }
        },
        async propertyView() {
            this.mainView = {
                id: null,
                Unit: {
                    number: 'Tenant',
                    type: 'tenant',
                }
            };
            if (this.propertyView) {
                await this.resetFilters();
                this.isDocumentReset = true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.img-view {
    position: relative;
    flex: 0 0 150px;

}

.img-view img {
    max-width: 40px;
    width: 100%;
}

.pdf-holder {
    padding: 0px;

}

.sign-modal-head {
    z-index: 10;
    position: relative;
}

.sign-modal {
    margin: 10px;
}


.lease-column-1 {
    background: #F4F6F8;
}

.ti-widget {
    .ti-widget__header {
        padding: 25px 5px 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .ti-widget__title {
            font-size: 1.2em;
            line-height: 1em;
            font-weight: bold;
            padding-top: 0px;
            padding-bottom: 5px;
        }

        .ti-widget__subtitle {
            opacity: 0.8;
            font-size: 0.9em;
            line-height: 1em;
            font-weight: normal;
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
}

.select-filter {
    border: 1px solid #DFE3E8;
    border-radius: 4px;
    width: 175px;
    height: 40px;
    background: white;
}

.select-filter:hover {
    border: 1px solid #C4CDD5;
    cursor: pointer;
}

.select-filter.download-center-filters-active {
    border: 2px solid #00848E;
}

.document-filters-footer {
    border: 1px solid #dfe3e8;
    border-top: none;
}


@media (min-width: 481px) and (max-width: 837px) {
    .document-button-wrapper {
        display: flex;
        flex-direction: column;

        .hb-btn-wrapper {
            &:first-child {
                margin-right: 0px !important;
            }
        }
    }
}

@media (max-width: 600px) {
    .document-button-wrapper {
        display: flex;
        flex-direction: row;

        .hb-btn-wrapper {
            &:first-child {
                margin-right: 10px !important;
            }
        }
    }
}
</style>
